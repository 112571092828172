/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RangeGroupDto } from './RangeGroupDto';
import {
    RangeGroupDtoFromJSON,
    RangeGroupDtoFromJSONTyped,
    RangeGroupDtoToJSON,
    RangeGroupDtoToJSONTyped,
} from './RangeGroupDto';

/**
 * 
 * @export
 * @interface SpecialityShopDto
 */
export interface SpecialityShopDto {
    /**
     * Speciality shop ID
     * @type {string}
     * @memberof SpecialityShopDto
     */
    id?: string;
    /**
     * Division ID
     * @type {string}
     * @memberof SpecialityShopDto
     */
    divId?: string;
    /**
     * Name of the speciality shop
     * @type {string}
     * @memberof SpecialityShopDto
     */
    name?: string;
    /**
     * Flag that says if a speciality shop is visible or invisible
     * @type {boolean}
     * @memberof SpecialityShopDto
     */
    hidden: boolean;
    /**
     * Speciality shop type C or L
     * @type {string}
     * @memberof SpecialityShopDto
     */
    type: string;
    /**
     * Volume that all items will occupy based on package/dimension and Sales Space Quantity
     * @type {number}
     * @memberof SpecialityShopDto
     */
    salesSpaceQuantityVolume?: number;
    /**
     * Area m2
     * @type {number}
     * @memberof SpecialityShopDto
     */
    areaM2: number;
    /**
     * Max Density
     * @type {number}
     * @memberof SpecialityShopDto
     */
    maxDensity?: number;
    /**
     * Planned Max Density
     * @type {number}
     * @memberof SpecialityShopDto
     */
    plannedMaxDensity: number;
    /**
     * Planned Filling Rate
     * @type {number}
     * @memberof SpecialityShopDto
     */
    plannedFillingRate: number;
    /**
     * Planned Density
     * @type {number}
     * @memberof SpecialityShopDto
     */
    plannedDensity?: number;
    /**
     * 
     * @type {Array<RangeGroupDto>}
     * @memberof SpecialityShopDto
     */
    rangeGroups?: Array<RangeGroupDto>;
    /**
     * String representation of the date/time the assignment was last updated. Intended for caching purposes only.
     * @type {string}
     * @memberof SpecialityShopDto
     */
    lastUpdated?: string;
}

/**
 * Check if a given object implements the SpecialityShopDto interface.
 */
export function instanceOfSpecialityShopDto(value: object): value is SpecialityShopDto {
    if (!('hidden' in value) || value['hidden'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('areaM2' in value) || value['areaM2'] === undefined) return false;
    if (!('plannedMaxDensity' in value) || value['plannedMaxDensity'] === undefined) return false;
    if (!('plannedFillingRate' in value) || value['plannedFillingRate'] === undefined) return false;
    return true;
}

export function SpecialityShopDtoFromJSON(json: any): SpecialityShopDto {
    return SpecialityShopDtoFromJSONTyped(json, false);
}

export function SpecialityShopDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialityShopDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'divId': json['divId'] == null ? undefined : json['divId'],
        'name': json['name'] == null ? undefined : json['name'],
        'hidden': json['hidden'],
        'type': json['type'],
        'salesSpaceQuantityVolume': json['salesSpaceQuantityVolume'] == null ? undefined : json['salesSpaceQuantityVolume'],
        'areaM2': json['areaM2'],
        'maxDensity': json['maxDensity'] == null ? undefined : json['maxDensity'],
        'plannedMaxDensity': json['plannedMaxDensity'],
        'plannedFillingRate': json['plannedFillingRate'],
        'plannedDensity': json['plannedDensity'] == null ? undefined : json['plannedDensity'],
        'rangeGroups': json['rangeGroups'] == null ? undefined : ((json['rangeGroups'] as Array<any>).map(RangeGroupDtoFromJSON)),
        'lastUpdated': json['lastUpdated'] == null ? undefined : json['lastUpdated'],
    };
}

  export function SpecialityShopDtoToJSON(json: any): SpecialityShopDto {
      return SpecialityShopDtoToJSONTyped(json, false);
  }

  export function SpecialityShopDtoToJSONTyped(value?: SpecialityShopDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'divId': value['divId'],
        'name': value['name'],
        'hidden': value['hidden'],
        'type': value['type'],
        'salesSpaceQuantityVolume': value['salesSpaceQuantityVolume'],
        'areaM2': value['areaM2'],
        'maxDensity': value['maxDensity'],
        'plannedMaxDensity': value['plannedMaxDensity'],
        'plannedFillingRate': value['plannedFillingRate'],
        'plannedDensity': value['plannedDensity'],
        'rangeGroups': value['rangeGroups'] == null ? undefined : ((value['rangeGroups'] as Array<any>).map(RangeGroupDtoToJSON)),
        'lastUpdated': value['lastUpdated'],
    };
}

