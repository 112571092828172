/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * HFB and it's parameters along with their values
 * @export
 * @interface HfbParameterDto
 */
export interface HfbParameterDto {
    /**
     * HFB ID
     * @type {string}
     * @memberof HfbParameterDto
     */
    id?: string;
    /**
     * HFB description
     * @type {string}
     * @memberof HfbParameterDto
     */
    name?: string;
    /**
     * High turnover mark parameter
     * @type {number}
     * @memberof HfbParameterDto
     */
    highTurnoverMark?: number;
    /**
     * Low turnover mark parameter
     * @type {number}
     * @memberof HfbParameterDto
     */
    lowTurnoverMark?: number;
}

/**
 * Check if a given object implements the HfbParameterDto interface.
 */
export function instanceOfHfbParameterDto(value: object): value is HfbParameterDto {
    return true;
}

export function HfbParameterDtoFromJSON(json: any): HfbParameterDto {
    return HfbParameterDtoFromJSONTyped(json, false);
}

export function HfbParameterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HfbParameterDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'highTurnoverMark': json['highTurnoverMark'] == null ? undefined : json['highTurnoverMark'],
        'lowTurnoverMark': json['lowTurnoverMark'] == null ? undefined : json['lowTurnoverMark'],
    };
}

  export function HfbParameterDtoToJSON(json: any): HfbParameterDto {
      return HfbParameterDtoToJSONTyped(json, false);
  }

  export function HfbParameterDtoToJSONTyped(value?: HfbParameterDto | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'highTurnoverMark': value['highTurnoverMark'],
        'lowTurnoverMark': value['lowTurnoverMark'],
    };
}

